<template>
    <div>
      <v-dialog
        v-model="supplierPaymentDialog"
        @click:outside="$emit('update:supplierPaymentDialog', false)"
        max-width="70%"
      >
        <SupplierCreditPaymentTable v-if="supplierPaymentDialog" :supplierPaymentDialog.sync="dialog" />
      </v-dialog>
    </div>
  </template>
  <script>
  import SupplierCreditPaymentTable from "@/components/receiptRecords/Tables/SupplierCreditPaymentTable.vue";
  
  export default {
    name: 'SupplierCreditPaymentDialog',
    components: {
        SupplierCreditPaymentTable
    },
    props: ['supplierPaymentDialog'],
    data() {
      return {
        dialog: false
      }
    },
    watch: {
      async dialog(val) {
        if (!val) {
          this.$emit('update:supplierPaymentDialog', false)
        }
      },
      supplierPaymentDialog(val) {
        if (!val) {
          this.dialog = false;
        } else {
          this.dialog = true;
        }
      }
    },
  }
  </script>